.react-flow__handle {
  height: 12px !important;
  width: 12px !important;
}

.react-flow__handle-top {
  top: -6px !important;
}

.react-flow__handle-bottom {
  bottom: -6px;
}

/* These styles are auto-applied to the node based on its type*/
.react-flow__node {
  background: white;
  border: 1px solid #1a192b;
  border-radius: 3px;
  color: #222;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 150px;
  cursor: grab;
}
